<template>
    <div v-if="opened" class="confirmation">
        <div class="message" v-if="message === ''">
            {{ $t('controls.really_delete_item') }}
        </div>
        <div class="buttons-group">
            <BaseButton @click="closeForm()">
                {{ $t('controls.no') }}
            </BaseButton>
            <BaseButton class="btn-danger" @click="runAction()">
                {{ $t('controls.yes') }}
            </BaseButton>
        </div>
    </div>
</template>

<script>
import { useStore } from 'vuex';
export default {
    name: "Confirmation",
    props: {
        message: {
            type: String,
            default: () => '',
        },
        opened: Boolean,
        action: {
            type: String,
            default: () => '',
        },
        params:  {
            type: Object,
            default: () => null,
        },
    },
    emits: ['close','confirmed'],
    setup(props,context){
        
        const closeForm = () => {
            context.emit('close');
        }

        const store = useStore();
        const runAction = () => {
            if(props.action){
                store.dispatch(props.action,props.params);
            }
            context.emit('confirmed');
            closeForm();
        }

        return {
            closeForm,
            runAction
        }
    }
}
</script>

<style scoped>
.confirmation{
    position: absolute;
    background: white;
    border: 3px solid #a10000;
    padding: 1rem;
    border-radius: 1rem;
    left: 0;
    top: 0;
    box-shadow: 2px 2px 20px 1px;
    margin: 0.5rem;
}
.confirmation .buttons-group{
    display: flex;
    justify-content: space-evenly;
    margin-top: 1rem;
}
</style>